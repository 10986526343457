import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Logo from "../../images/casestudies/wellness/wellness_logo.svg";
import Img01 from "../../images/casestudies/wellness/img01.png";
import PersonImg from "../../images/casestudies/teemates/review_logo.svg";
import Link from 'gatsby-link';
import BgImage from "../../images/casestudies/wellness/bgimg.png";


const {useRef, useState, useEffect} = React;
const imgixAPI = [
    {
        src: Img01,
        alt: "Wellness"
    }
];
const Bgimage = [
    {
        src: BgImage,
        alt: "Wellness Background"
    }
];


const LazyImage = ({src, alt}) => {
    const rootRef = useRef();

    const [isVisible, setIsVisible] = useState(false);
    const loading = 'https://www.tekrevol.com/Tek-Spinner.gif';

    useEffect(() => {
        const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: '0px',
        };

        const checkIntersections = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        };

        if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(rootRef.current);
            return () => newIO.disconnect();
        }
    }, [isVisible]);
    return (
        <img src={isVisible ? src : loading} ref={rootRef} className="img-fluid" alt={alt}/>
    );
};

function WellnessProject(props) {
    return (
        <Container className="h-100">
            <Row className="h-100">
                <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="tekrevol_project_item">
                    <div className="wellness_project">
                        <Row>
                            <Col xs={12} md={7} lg={6} xl={6} xxl={6}>
                                <div className="project_info">
                                    <div className="logo_project">
                                        <img src={Logo} alt="wellness Logo"/>
                                    </div>
                                    <div className="framework_project">
                                        <ul>
                                            <li>IOS App</li>
                                            <li>Android App</li>
                                            <li>UI UX Design</li>
                                        </ul>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={12} md={5} lg={6} xl={6} xxl={6}>
                                <div className="project_statistics">
                                    <div className="download">
                                        1M+
                                        <span>Downlaod</span>
                                    </div>
                                    <div className="reward">
                                        Reward
                                        <span>2 Awards</span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={6} xl={6} xxl={6}>
                                <div className="project_title">
                                    <h3>AMJ Workplace Wellness</h3>
                                </div>
                                <div className="img_prev">
                                    {imgixAPI.map((el, i) => (
                                        <LazyImage
                                            alt={el.alt}
                                            src={el.src}
                                            key={i}

                                        />
                                    ))}
                                    {/*<img src={Img01} alt="Image 01" className="img-fluid"/>*/}
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={6} xl={6} xxl={6}>
                                <div className="project_title">
                                    <p>More and more businesses have started advocating the employee wellbeing and
                                        fitness. Our client, as a modern leader understands that employee wellness is a
                                        primary factor in any organization’s success. </p>
                                    <p>Workers’ physical, mental and emotional health impact the overall efficiency and
                                        effectiveness of business operations, regardless of the sector or market a
                                        business operates in.</p>
                                    <p>Workers’ physical, mental and emotional health impact the overall efficiency and
                                        effectiveness of business operations, regardless of the sector or market a
                                        business operates in.</p>
                                    <div className="project_link_btn">
                                        <Link to=""> View Full Case Study
                                            <svg viewBox="0 0 21 12" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989592 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z"
                                                    fill="#F47A1F"/>
                                            </svg></Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="bg_iamge">
                            {Bgimage.map((el, i) => (
                                <LazyImage
                                    alt={el.alt}
                                    src={el.src}
                                    key={i}

                                />
                            ))}
                            {/*<img src={BgImage} alt="backgorund_img" className="img-fluid"/>*/}
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={9} lg={7} xl={6} xxl={6} className="m-auto tekrevol_project_review">
                    <div className="project_review">
                        <p>“We’re very happy with Tekrevol. They seem to work in the way that we do, and we have a close
                            collaboration with them. Every day we talk to the developers and outline what needs to be
                            done."
                            <svg width="218" height="171" viewBox="0 0 218 171" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.482788 0.492676H94.9834V95.0883L57.0882 170.879H9.98034L47.6856 95.0883H0.482788V0.492676Z"
                                    fill="#E2E2E2"/>
                                <path
                                    d="M122.542 0.492676H217.042V95.0883L179.147 170.879H132.039L169.744 95.0883H122.542V0.492676Z"
                                    fill="#E2E2E2"/>
                            </svg>

                        </p>
                        <div className="review_info">
                            <div className="preson">
                                <img src={PersonImg} alt="Henrik Loop" className="img-fluid"/>
                            </div>
                            <div className="preson_info">
                                <h6>Henrik Loop</h6>
                                <div className="designation">
                                    CTO at Blockbuster, TDC
                                </div>
                                <div className="project_link">
                                    <Link to=""> Full Case Study
                                        <svg viewBox="0 0 21 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989592 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z"
                                                fill="#F47A1F"/>
                                        </svg></Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default WellnessProject;